import { products } from '@cc/viewers';

const { createIntegrationBinding } = products.ffr;

const {
  actions: ffrViewActions,
  rootReducer: ffrViewReducer,
  selectors: ffrViewSelectors,
} = createIntegrationBinding('viewer');

export { ffrViewActions, ffrViewReducer, ffrViewSelectors };
