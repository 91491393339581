import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  margin: 0;
  padding: 0;
  border: none;
  overflow: none;
`;

interface Props {
  attachDom: (dom: HTMLElement) => void;
  detachDom: () => void;
  className?: string;
}

export const DomContainer: React.FC<Props> = ({
  attachDom,
  detachDom,
  className,
  children,
}) => {
  const containerEl = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (containerEl.current) {
      attachDom(containerEl.current);
    }
    return detachDom;
  }, [containerEl, attachDom, detachDom]);
  return (
    <Container ref={containerEl} className={className}>
      {children}
    </Container>
  );
};
