/* eslint-disable react/no-array-index-key */
import React from 'react';

import styled from 'styled-components';
import { modules } from '@cc/viewers';
import { OverlayDirection } from './overlaydata';
import Platform from './platformComponents';

const { NotImplemented } = modules;

// TODO: T11336. complete it
const posFromDirection = (direction: OverlayDirection) => {
  const padding = `padding:4px 8px;`;

  switch (direction) {
    case OverlayDirection.Left:
      return `
        left:0;
        top:50%;
        transform: translateY(-50%);
        ${padding}
      `;
    case OverlayDirection.Right:
      return `
        right:0;
        top:50%;
        transform: translateY(-50%);
        ${padding}
      `;
    case OverlayDirection.Top:
      return `  
        top: 0;
        left:50%;
        transform: translateX(-50%);
        ${padding}
      `;
    case OverlayDirection.Bottom:
      return `
        bottom: 0px;
        left:50%;
        transform: translateX(-50%);
        ${padding}
      `;
    case OverlayDirection.LeftTop:
      return `
        left: 0;
        top: 0;
        ${padding}
      `;
    case OverlayDirection.RightTop:
      return `
        right: 0;
        top: 0;
        ${padding}
        text-align: right;
      `;
    case OverlayDirection.LeftBottom:
      return `
        left: 0;
        bottom: 0;
        ${padding}
      `;
    case OverlayDirection.RightBottom:
      return `
        right: 0;
        buttom: 0px;
        ${padding}
      `;
    case OverlayDirection.Center:
      return `
        width: 100%;
        height: 100%;
        transform: translateX(-50%);
        transform: translateY(-50%);
      `;
    default:
      throw new NotImplemented(
        `Overlay, direction(${direction}) is not implemented`
      );
  }
};

interface ContainerProps {
  direction: OverlayDirection;
}

const Container = styled.div<ContainerProps>`
  position: absolute;
  pointer-events: none;
  opacity: 0.85;
  font-size: 14px;
  font-weight: 600;
  color: #ffffff;
  line-height: 22px;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  ${(props) => posFromDirection(props.direction)}
`;

interface Props {
  direction: OverlayDirection;
  data: Array<string>;
  radius: number | undefined;
}

export const OverlayItem: React.FC<Props> = ({ direction, data, radius }) => {
  return (
    <Container direction={direction}>
      {data.map((s, i) => {
        const str = s.split(' ');

        return (
          <>
            {str.length === 2 ? (
              <div key={i}>
                <Platform.SelectedValue
                  ffrName={str[0]}
                  ffrValue={str[1]}
                  radius={radius}
                />
              </div>
            ) : (
              <div key={i}>{s}</div>
            )}
          </>
        );
      })}
    </Container>
  );
};
