import React, { useCallback } from 'react';
import styled from 'styled-components';
import { Overlay } from './overlay';
import { FFRColorBar } from './ffrcolorbar';
import { OverlayData } from './overlaydata';
import { DomContainer } from './domcontainer';

import { isMobile } from '../util/ismobile';

const Container = styled.div`
  height: 100%;
  width: 100%;
  user-select: none;
  color: #ffffff;
  background-color: #000;
  font-size: 12px;
  position: relative;
  div[direction='5'] {
    position: absolute;
    top: 48px;
    right: 70px;
    padding: 0;
    line-height: 2.2em;
    margin: 16px 0;
    font-family: PingFangSC-Medium;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.65);
    text-align: right;
    line-height: 24px;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
    span {
      font-size: 20px;
      color: rgba(255, 255, 255, 0.85);
      text-align: right;
      line-height: 32px;
    }
  }
`;

export interface FFRViewProps {
  attachParent: (parent: HTMLElement) => void;
  attachInput: (input: HTMLElement) => void;
  detachParent: () => void;
  detachInput: () => void;
  overlayData?: OverlayData;
  ffrValue: number | undefined;
  radius: number | undefined;
}

export const FFRView: React.FC<FFRViewProps> = ({
  attachParent,
  attachInput,
  detachParent,
  detachInput,
  overlayData,
  ffrValue,
  radius,
}) => {
  const attachDom = useCallback(
    (dom: HTMLElement) => {
      attachParent(dom);
      attachInput(dom);
    },
    [attachParent, attachInput]
  );
  const detachDom = useCallback(() => {
    detachParent();
    detachInput();
  }, [detachParent, detachInput]);
  return (
    <Container>
      <DomContainer attachDom={attachDom} detachDom={detachDom} />
      {overlayData && <Overlay radius={radius} overlayData={overlayData} />}
      <FFRColorBar ffrValue={ffrValue} right={isMobile() ? 10 : 70} />
    </Container>
  );
};
