import React from 'react';
import styled from 'styled-components';

import { PlatformInterface, SelectedValueProps } from './types';

const Wrapper = styled.section<{ right: number }>`
  position: absolute;
  right: ${(props) => props.right}px;
  top: 50%;
  transform: translateY(-50%);

  display: flex;
  height: 350px;
  color: #ddf8ff;
  font-size: 12px;
  pointer-events: none;
`;

const BaseScale = styled.li<{ 'data-offset': string }>`
  position: absolute;
  display: flex;
  justify-content: right;
  right: 0;
  top: ${(props) => 100 * Number(props['data-offset'])}%;
`;

const MinorScale = styled(BaseScale)`
  &::after {
    content: '';
    display: block;
    height: 1px;
    width: 4px;
    background-color: #fff;
  }
`;

const MajorScale = styled(BaseScale)`
  &::before {
    content: attr(data-scale);
    display: block;
    transform: translate(-20%, -50%);
  }
  &::after {
    content: '';
    display: block;
    height: 1px;
    width: 8px;
    background-color: #fff;
  }
`;

const RulerWrapper = styled.ul`
  position: relative;
  height: calc(100% - 1px);
  margin: 0;
  padding: 0;
  list-style: none;
`;

const ColorBar = styled.div<{ gradient: string }>`
  flex-shrink: 0;
  height: 100%;
  width: 12px;
  background: linear-gradient(${(props) => props.gradient});
`;

const Arrow = styled.i<{ offset: string }>`
  position: absolute;
  left: 5px;
  margin-top: -5px;
  border-style: solid;
  border-color: transparent #fff transparent transparent;
  border-width: 5px 10px 5px 0;
  top: ${(props) => props.offset};
`;

const SelectedValue: React.FC<SelectedValueProps> = ({ ffrName, ffrValue }) => (
  <div>
    {ffrName}
    <span>{` ${ffrValue}`}</span>
  </div>
);

const desktop: PlatformInterface = {
  Wrapper,
  BaseScale,
  MinorScale,
  MajorScale,
  RulerWrapper,
  ColorBar,
  Arrow,
  SelectedValue,
};

export default desktop;
