import { connect } from 'react-redux';
import { FFRView } from '../uicomponent/ffrsceneview';
import { ffrViewSelectors, ffrViewActions, RootState } from '../setup';

const mapStateToProps = (state: RootState) => ({
  overlayData: ffrViewSelectors.ffrData.overlayDataSelector(state.viewer),
  ffrValue: ffrViewSelectors.ffrData.FFRValueSelector(state.viewer),
  radius: state.viewer.ffrData.selectedFFRItem?.radius,
});

const mapDispatchToProps = ffrViewActions.ffrView;

export const FFRViewer = connect(mapStateToProps, mapDispatchToProps)(FFRView);
