import React, { useEffect, useState } from 'react';
import { image, centerline } from '@cc/loader';
import ReactDOM from 'react-dom';
import { BufferGeometry } from 'three';
import { Spin, message } from 'antd';
import styled from 'styled-components';
import URL from 'url';
import { Provider } from 'react-redux';
import * as viewers from '@cc/viewers';
import { ffrViewActions, setupStore } from './setup';
import { FFRViewer } from './container';
import { getDicomFFR } from './util/getimage';
import 'antd/dist/antd.css';
import './index.css';
import { isMobile } from './util/ismobile';

const { Image3DSpaceInfo } = image;
interface DataType {
  info?: image.Image3DSpaceInfo;
  geo: BufferGeometry;
  centerlines: Array<centerline.Centerline>;
}

type VType = [number, number, number];

const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgb(245, 245, 245);
  display: flex;
  user-select: none;
  position: relative;
  justify-content: center;
  align-items: center;
  div[direction='4'] {
    position: absolute;
    top: 24px;
    left: 24px;
    font-size: 18px;
    padding: 0;
    line-height: ${isMobile() ? '1.2em' : '2.2em'};
    margin: 16px 0;
  }
`;

const Entry = () => {
  const [app, setApp] = useState<DataType>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    async function App() {
      const r = URL.parse(window.location.href, true).query;

      if (!Array.isArray(r.url) && r.url) {
        try {
          const data = await getDicomFFR(unescape(r.url));
          const info = data.info
            ? new Image3DSpaceInfo(
                data.info.size as VType,
                data.info.spacing as VType,
                data.info.origin as VType
              )
            : undefined;

          setLoading(false);
          setApp({
            info,
            geo: data.geo,
            centerlines: data.centerlines,
          });
        } catch (error) {
          setLoading(false);
          message.warning(
            'can not retrieve DICOM file with the given "url" param'
          );
        }
      } else {
        setLoading(false);
        message.warning('"url" is not found in the query params');
      }
    }
    App();
  }, []);

  if (app && !loading) {
    const store = setupStore();

    store.dispatch(
      ffrViewActions.integration.init({
        props: {
          info: app.info,
          indicatorType: isMobile()
            ? viewers.products.common.prefab.IndicatorType.None
            : viewers.products.common.prefab.IndicatorType.Cube,
          geos: [app.geo],
          centerlines: app.centerlines,
          hitTestMode: isMobile()
            ? viewers.products.common.prefab.FFRHitTestMode.RayDistance
            : undefined,
          initialZoom: app.info ? 1 : 0.6,
        },
        input: isMobile() ? new viewers.engine.HammerJSInput() : undefined,
      })
    );
    return (
      <Provider store={store}>
        <Container>
          <FFRViewer />
        </Container>
      </Provider>
    );
  }
  return <Container>{loading ? <Spin size="large" /> : null}</Container>;
};

ReactDOM.render(<Entry />, document.getElementById('root'));
