import { configureStore } from '@reduxjs/toolkit';

import { rootReducer } from './rootreducer';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const setupStore = () => {
  const store = configureStore({
    reducer: rootReducer,
  });

  return store;
};
