import React, { useMemo } from 'react';

import Platform from './platformComponents';

interface RulerProps {
  valueRange: Array<number>;
  unit: { major: number; minor: number };
}
const Ruler = React.memo(({ valueRange, unit }: RulerProps) => {
  const { major, minor } = unit;
  const [min, max] = valueRange;

  const oom = minor < 1 ? 10 ** Math.round(1 / minor).toString().length : 1;
  const scales = [];

  for (let val = min; val <= max; val = Math.round((val + minor) * oom) / oom) {
    const offset = (max - val) / (max - min);
    const Scale =
      Math.round(val * oom) % Math.round(major * oom) === 0
        ? Platform.MajorScale
        : Platform.MinorScale;

    scales.unshift(
      <Scale
        key={val}
        data-offset={offset.toFixed(6)}
        data-scale={val.toFixed(1)}
      />
    );
  }

  return <Platform.RulerWrapper>{scales}</Platform.RulerWrapper>;
});

interface FFRColorBarProps {
  colors?: Array<string>;
  valueRange?: Array<number>;
  unit?: { major: number; minor: number };
  ffrValue?: number;
  style?: React.CSSProperties;
  right?: number;
}
function FFRColorBar({
  ffrValue = 0.5,
  valueRange = [0.5, 1],
  unit = { major: 0.1, minor: 0.02 },
  colors = [
    '#1264FC',
    '#0095FF',
    '#0BE2CC',
    '#00FF00',
    '#BCF000',
    '#FE8E14',
    '#FF4629',
    '#FF2706',
    '#FD0200',
    '#FD0200 ',
    '#FD0200',
  ],
  style,
  right,
}: FFRColorBarProps): JSX.Element {
  const gradient = colors
    .map((color, index) => `${color} ${(index / (colors.length - 1)) * 100}%`)
    .join(', ');
  const [min, max] = valueRange;

  const val = Math.max(min, Math.min(max, ffrValue));
  const offset = `${(100 * (max - val)) / (max - min)}%`;

  return useMemo(
    () => (
      <Platform.Wrapper style={style} right={right || 70}>
        <Ruler valueRange={valueRange} unit={unit} />
        <Platform.ColorBar gradient={gradient} />
        <Platform.Arrow offset={offset} />
      </Platform.Wrapper>
    ),
    [gradient, offset, right, style, unit, valueRange]
  );
}

export { FFRColorBar };
