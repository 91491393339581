/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  FetcherMgr,
  FetchType,
  ResourceMgr,
  fetchUrl,
  loadResource,
  image,
  Locator,
  AnyOption,
  Data,
  PayLoadType,
  AnyResource,
  centerline,
  dicom,
  ParseFn,
  IResourceMgr,
} from '@cc/loader';

import { BufferGeometry } from 'three';
import { modules } from '@cc/viewers';

const { parsePly } = modules;

dicom.addCustomTags(dicom.cura);
const defaultFetcher = new FetcherMgr();
const hackFetchUrl = async (locator: Locator, option: AnyOption) => {
  const data = await fetchUrl(locator, option);
  return data;
};
defaultFetcher.register(FetchType.Url, hackFetchUrl);
const mgr = new ResourceMgr(defaultFetcher);

type DicomFFRValues = {
  info?: image.ImageInfo;
  geo: BufferGeometry;
  centerlines: Array<centerline.Centerline>;
};

const uint8ToString = (uint8Array: Uint8Array): string => {
  const isPadded = uint8Array[uint8Array.length - 1] === 0;
  const finalArray = isPadded
    ? new Uint8Array(
        uint8Array.buffer,
        uint8Array.byteOffset,
        uint8Array.length - 1
      )
    : uint8Array;
  return new TextDecoder().decode(finalArray);
};

const parseDicomFFR: ParseFn = async (param: {
  mgr: IResourceMgr;
  data: Data;
}): Promise<AnyResource<DicomFFRValues>> => {
  const fullDicom = dicom.parseDicom(param.data.getArrayBuffer());
  const ply = ((await parsePly({
    data: new Data(
      PayLoadType.STRING,
      uint8ToString(fullDicom.getTag('CURA_PLY'))
    ),
    mgr,
    option: {},
  })) as AnyResource<BufferGeometry>).data;
  const left = ((await centerline.parseCenterline({
    data: new Data(
      PayLoadType.STRING,
      uint8ToString(fullDicom.getTag('CURA_CL_LEFT'))
    ),
  })) as AnyResource<centerline.Centerline>).data;
  const right = ((await centerline.parseCenterline({
    data: new Data(
      PayLoadType.STRING,
      uint8ToString(fullDicom.getTag('CURA_CL_RIGHT'))
    ),
  })) as AnyResource<centerline.Centerline>).data;
  const infoData = fullDicom.getTag('CURA_IMAGEINFO');
  const info = infoData ? JSON.parse(uint8ToString(infoData)) : undefined;
  if (info) {
    info.origin[0] = -info.origin[0];
    info.origin[1] = -info.origin[1];
  }

  return new AnyResource<DicomFFRValues>({
    info,
    geo: ply,
    centerlines: [left, right],
  });
};
export const getDicomFFR = async (url: string): Promise<DicomFFRValues> => {
  const rep = await loadResource(mgr, {
    fetchType: FetchType.Url,
    locator: {
      url,
    },
    parse: parseDicomFFR,
  });
  return rep.get<AnyResource<DicomFFRValues>>().data;
};
