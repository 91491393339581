const colors = [
  'rgba(17, 100, 252, 255)',
  'rgba(14, 110, 253, 255)',
  'rgba(10, 119, 253, 255)',
  'rgba(6, 130, 254, 255)',
  'rgba(3, 140, 254, 255)',
  'rgba(0, 151, 254, 255)',
  'rgba(2, 166, 243, 255)',
  'rgba(4, 182, 233, 255)',
  'rgba(7, 198, 222, 255)',
  'rgba(9, 213, 212, 255)',
  'rgba(11, 227, 195, 255)',
  'rgba(8, 233, 152, 255)',
  'rgba(6, 238, 112, 255)',
  'rgba(3, 245, 69, 255)',
  'rgba(1, 250, 28, 255)',
  'rgba(11, 254, 0, 255)',
  'rgba(50, 251, 0, 255)',
  'rgba(88, 248, 0, 255)',
  'rgba(126, 244, 0, 255)',
  'rgba(164, 242, 0, 255)',
  'rgba(193, 232, 1, 255)',
  'rgba(206, 212, 5, 255)',
  'rgba(220, 192, 9, 255)',
  'rgba(233, 172, 14, 255)',
  'rgba(247, 152, 17, 255)',
  'rgba(254, 134, 21, 255)',
  'rgba(254, 120, 26, 255)',
  'rgba(254, 105, 30, 255)',
  'rgba(255, 90, 35, 255)',
  'rgba(255, 76, 39, 255)',
  'rgba(255, 66, 37, 255)',
  'rgba(255, 59, 29, 255)',
  'rgba(255, 53, 22, 255)',
  'rgba(255, 46, 15, 255)',
  'rgba(255, 41, 8, 255)',
  'rgba(255, 34, 5, 255)',
  'rgba(255, 26, 4, 255)',
  'rgba(255, 18, 3, 255)',
  'rgba(255, 11, 2, 255)',
  'rgba(255, 4, 0, 255)',
  'rgba(255, 2, 0, 255)',
  'rgba(255, 2, 0, 255)',
  'rgba(255, 2, 0, 255)',
  'rgba(255, 2, 0, 255)',
  'rgba(255, 2, 0, 255)',
  'rgba(255, 2, 0, 255)',
  'rgba(255, 2, 0, 255)',
  'rgba(255, 2, 0, 255)',
  'rgba(255, 2, 0, 255)',
  'rgba(255, 2, 0, 255)',
];

export default colors.reverse();
