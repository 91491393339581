import React from 'react';
import styled from 'styled-components';

import colorsArray from '../../util/clolrs';
import { PlatformInterface, SelectedValueProps } from './types';

const MOBILECOLORBARWIDTH = '80vw';

const Wrapper = styled.section`
  position: absolute;
  left: 50%;
  top: 93%;
  transform: translateX(-50%);

  width: ${MOBILECOLORBARWIDTH};
  color: #ddf8ff;
  font-size: 12px;
  pointer-events: none;
`;

const BaseScale = styled.span<{ 'data-offset': string }>`
  position: absolute;
  display: flex;
  left: ${(props) => 100 * Number(props['data-offset'])}%;
`;

const MinorScale = styled(BaseScale)``;

const MajorScale = styled(BaseScale)`
  &::before {
    content: attr(data-scale);
    display: block;
    transform: translate(-45%, -110%);
    position: absolute;
  }
  &::after {
    content: '';
    display: block;
    height: 3px;
    width: 1px;
    background-color: rgba(255, 255, 255, 0.65);
    margin-top: -1px;
  }
`;

const RulerWrapper = styled.div`
  position: relative;
  width: ${`calc(${MOBILECOLORBARWIDTH} - 1px)`};
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  top: -2px;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.65);
`;

const ColorBar = styled.div<{ gradient: string }>`
  flex-shrink: 0;
  height: 6px;
  width: ${MOBILECOLORBARWIDTH};
  background: linear-gradient(${(props) => `90deg ,${props.gradient}`});
`;

const Arrow = styled.i<{ offset: string }>`
  position: absolute;
  left: ${(props) => `calc(${props.offset} - 5px)`};
  margin-top: -14px;
  border-style: solid;
  border-color: transparent transparent #fff transparent;
  border-width: 10px 5px;
`;

const SelectedContainer = styled.div`
  & .name {
    color: rgba(255, 255, 255, 0.65);
    font-weight: normal;
    font-size: 14px;
  }

  & .bottom-margin {
    margin-bottom: 12px;
  }
`;

const FFRdiv = styled.div<{ color: string }>`
  font-size: 20px;
  color: ${(props) => props.color};
  line-height: 24px;
`;

const SelectedValue: React.FC<SelectedValueProps> = ({ ffrValue, radius }) => (
  <SelectedContainer>
    <div className="name">Diameter</div>
    <div className="bottom-margin">
      {radius ? (radius * 2).toFixed(2) : 0} mm
    </div>

    <div className="name">DVFFR</div>
    <FFRdiv
      color={colorsArray[Math.floor((Number(ffrValue) - 0.5) * 100 - 1)]}
    >{` ${Number(ffrValue)}`}</FFRdiv>
  </SelectedContainer>
);

const mobile: PlatformInterface = {
  Wrapper,
  BaseScale,
  MinorScale,
  MajorScale,
  RulerWrapper,
  ColorBar,
  Arrow,
  SelectedValue,
};

export default mobile;
