import React from 'react';
import { OverlayData, OverlayDirection } from './overlaydata';
import { OverlayItem } from './overlayItem';

interface Props {
  overlayData: OverlayData;
  radius: number | undefined;
}

export const Overlay: React.FC<Props> = ({ overlayData, radius }) => {
  return (
    <>
      {overlayData.directionData.map((d, i) =>
        d == null || d.length === 0 ? undefined : (
          <OverlayItem
            radius={radius}
            key={i as OverlayDirection}
            direction={i as OverlayDirection}
            data={d}
          />
        )
      )}
    </>
  );
};
