export enum OverlayDirection {
  Left,
  Right,
  Top,
  Bottom,
  LeftTop,
  RightTop,
  LeftBottom,
  RightBottom,
  Center,
  Count,
}

export interface OverlayData {
  directionData: Array<Array<string>>;
}
